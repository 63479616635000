import * as React from "react"

import * as styles from "./PrivacyPolicy.module.css"
import Header from "../header"
import Footer from "../footer"

import { useIntl } from "gatsby-plugin-intl-v4"

const PrivacyPolicy = () => {

    const intl = useIntl();

    return (
        <div className={`pageContainer`}>
            <Header hidePlaceholder={true} />
            <section className={`${styles.mainSection}`}>
                <h1 className={styles.title}>
                    {intl.formatMessage({ id: 'navigation.privacyPolicy' }).split('<br/>').join('\n')}
                </h1>
                <div className={styles.divider}></div>

                <div className={styles.textRowColumn} style={{marginBottom: "20px"}}>
                    <a href="#" className="cky-banner-element">
                        {intl.formatMessage({ id: 'privacyPolicy.cookieLink' })}
                    </a>
                </div>

                <h4 className={styles.subtitle}>
                    {intl.formatMessage({ id: 'privacyPolicy.subtitle' }).split('<br/>').join('\n')}
                </h4>

                <div className={styles.divider}></div>
                <div className={`row ${styles.mainRow}`}>
                    {/* GAUCHE */}
                    <div className={`col-xl-6 ${styles.left}`}>
                        <h5>{intl.formatMessage({ id: 'privacyPolicy.titleInfoCollected' }).split('<br/>').join('\n')}</h5>
                        <div className={styles.smallAndReduced}>
                            <p>
                                {intl.formatMessage({ id: 'privacyPolicy.textInfoCollected' }).split('<br/>').join('\n')}
                            </p>
                        </div>
                        <h6>{intl.formatMessage({ id: 'privacyPolicy.subtitlePersonalInfo' }).split('<br/>').join('\n')}</h6>
                        <p>{intl.formatMessage({ id: 'privacyPolicy.textPersonalInfo1' }).split('<br/>').join('\n')}</p>
                        <p>{intl.formatMessage({ id: 'privacyPolicy.textPersonalInfo2' }).split('<br/>').join('\n')}</p>
                        <h6>{intl.formatMessage({ id: 'privacyPolicy.subtitleNonPersonalInfo' }).split('<br/>').join('\n')}</h6>
                        <p>{intl.formatMessage({ id: 'privacyPolicy.textNonPersonalInfo' }).split('<br/>').join('\n')}</p>
                        <p className={`${intl.locale === 'fr' ? "" : styles.hide}`}>
                            {intl.formatMessage({ id: 'privacyPolicy.textNonPersonalInfo2' }).split('<br/>').join('\n')}
                        </p>
                        <h6>{intl.formatMessage({ id: 'privacyPolicy.subtitleTechInfo' }).split('<br/>').join('\n')}</h6>
                        <p>{intl.formatMessage({ id: 'privacyPolicy.textTechInfo' }).split('<br/>').join('\n')}</p>
                        <div className={styles.divider}></div>

                        <h5>{intl.formatMessage({ id: 'privacyPolicy.titlePersonalInfoUsed' }).split('<br/>').join('\n')}</h5>
                        <div className={styles.smallAndReduced}>
                            <p>
                                {intl.formatMessage({ id: 'privacyPolicy.textPersonalInfoUsed' }).split('<br/>').join('\n')}
                            </p>
                        </div>
                        <h6>{intl.formatMessage({ id: 'privacyPolicy.subtitleInfoCollectedSocialMedia' }).split('<br/>').join('\n')}</h6>
                        <p>{intl.formatMessage({ id: 'privacyPolicy.textInfoCollectedSocialMedia' }).split('<br/>').join('\n')}</p>
                        <div className={styles.divider}></div>

                        <h5>{intl.formatMessage({ id: 'privacyPolicy.titleRetention' }).split('<br/>').join('\n')}</h5>
                        <p>{intl.formatMessage({ id: 'privacyPolicy.textRetention1' }).split('<br/>').join('\n')}</p>
                        <p>{intl.formatMessage({ id: 'privacyPolicy.textRetention2' }).split('<br/>').join('\n')}</p>
                        <p>{intl.formatMessage({ id: 'privacyPolicy.textRetention3' }).split('<br/>').join('\n')}</p>
                    </div>

                    {/* DROITE */}
                    <div className={`col-xl-6 ${styles.right}`}>
                        <h5>{intl.formatMessage({ id: 'privacyPolicy.titleAccessModify' }).split('<br/>').join('\n')}</h5>
                        <p>{intl.formatMessage({ id: 'privacyPolicy.textAccessModify1' }).split('<br/>').join('\n')}</p>

                        <div className={`row ${styles.textRow}`}>
                            <div className={`col-xl-6 ${styles.textRowColumn}`}>
                                <h6>{intl.formatMessage({ id: 'privacyPolicy.subtitleAccessMail' }).split('<br/>').join('\n')}</h6>
                                <p>{intl.formatMessage({ id: 'privacyPolicy.textAccessMail' }).split('<br/>').join('\n')}</p>
                            </div>
                            <div className={`col-xl-6 ${styles.textRowColumn}`}>
                                <h6>{intl.formatMessage({ id: 'privacyPolicy.subtitleAccessEmail' }).split('<br/>').join('\n')}</h6>
                                <p>
                                    <a className={styles.color} href="mailto:privacyofficer@briviagroup.ca">
                                        privacyofficer@briviagroup.ca
                                    </a>
                                    <br />
                                    {intl.formatMessage({ id: 'privacyPolicy.textAccessEMail' }).split('<br/>').join('\n')}</p>
                            </div>
                        </div>

                        <p>{intl.formatMessage({ id: 'privacyPolicy.textAccessModify2' }).split('<br/>').join('\n')}</p>
                        <p className={`${intl.locale === 'en' ? "" : styles.hide}`}>
                            {intl.formatMessage({ id: 'privacyPolicy.textAccessModify3' }).split('<br/>').join('\n')}
                        </p>
                        <div className={styles.divider}></div>

                        <h5>{intl.formatMessage({ id: 'privacyPolicy.titleDisclosureInfo' }).split('<br/>').join('\n')}</h5>
                        <p>{intl.formatMessage({ id: 'privacyPolicy.textDisclosureInfo1' }).split('<br/>').join('\n')}</p>
                        <p>{intl.formatMessage({ id: 'privacyPolicy.textDisclosureInfo2' }).split('<br/>').join('\n')}</p>
                        <div className={styles.divider}></div>

                        <h5>{intl.formatMessage({ id: 'privacyPolicy.titleOutsideLinks' }).split('<br/>').join('\n')}</h5>
                        <p>{intl.formatMessage({ id: 'privacyPolicy.textOutsideLinks' }).split('<br/>').join('\n')}</p>
                        <div className={styles.divider}></div>

                        <h5>{intl.formatMessage({ id: 'privacyPolicy.titleChildren' }).split('<br/>').join('\n')}</h5>
                        <p>{intl.formatMessage({ id: 'privacyPolicy.textChildren' }).split('<br/>').join('\n')}</p>
                        <div className={styles.divider}></div>

                        <h5>{intl.formatMessage({ id: 'privacyPolicy.titleChanges' }).split('<br/>').join('\n')}</h5>
                        <p>{intl.formatMessage({ id: 'privacyPolicy.textChanges' }).split('<br/>').join('\n')}</p>
                        <div className={styles.divider}></div>

                        <h5>{intl.formatMessage({ id: 'privacyPolicy.titleComments' }).split('<br/>').join('\n')}</h5>
                        <p>{intl.formatMessage({ id: 'privacyPolicy.textComments' }).split('<br/>').join('\n')}</p>
                        <div className={`row ${styles.textRow}`}>
                            <div className={`col-xl-6 ${styles.textRowColumn}`}>
                                <h6>{intl.formatMessage({ id: 'privacyPolicy.subtitleAccessMail' }).split('<br/>').join('\n')}</h6>
                                <p>{intl.formatMessage({ id: 'privacyPolicy.textAccessMail' }).split('<br/>').join('\n')}</p>
                            </div>
                            <div className={`col-xl-6 ${styles.textRowColumn}`}>
                                <h6>{intl.formatMessage({ id: 'privacyPolicy.subtitleAccessEmail' }).split('<br/>').join('\n')}</h6>
                                <p>
                                    <a className={styles.color} href="mailto:info@briviagroup.ca">
                                        info@briviagroup.ca
                                    </a>
                                    <br />
                                    {intl.formatMessage({ id: 'privacyPolicy.textAccessEMail' }).split('<br/>').join('\n')}</p>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
            <Footer variant={4} hideBorder={true} />
        </div>
    )
}

export default PrivacyPolicy
