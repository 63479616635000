// extracted by mini-css-extract-plugin
export var color = "PrivacyPolicy-module--color--Fq1o7";
export var divider = "PrivacyPolicy-module--divider--piZ5m";
export var hide = "PrivacyPolicy-module--hide--yFa6I";
export var left = "PrivacyPolicy-module--left--1twab";
export var mainRow = "PrivacyPolicy-module--mainRow--MBPG7";
export var mainSection = "PrivacyPolicy-module--mainSection--AekO2";
export var right = "PrivacyPolicy-module--right--0H+pi";
export var smallAndReduced = "PrivacyPolicy-module--smallAndReduced--rTj0w";
export var subtitle = "PrivacyPolicy-module--subtitle--lB0I4";
export var textRow = "PrivacyPolicy-module--textRow--Gf0Nb";
export var textRowColumn = "PrivacyPolicy-module--textRowColumn--J-O-R";
export var title = "PrivacyPolicy-module--title--FVaYk";